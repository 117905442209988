import React, { Component } from 'react';
import './Home.css'
import Web3 from 'web3';
import CoolNFT from '../truffle_abis/CoolNFT.json';

//meepie rarity chart?
//view my meepies
//

let listofNfts = [];
let walletAddress = '0x0';

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            account: '0x0',
            loading: true,
            statusCode: 0, //0 ready to play, //1 no metamask, //2 is not connected //3 not on the right network  //4 don't own meepie
            account: '0x0'
        }

    }

    async UNSAFE_componentWillMount() {
        await this.loadWeb3()
    }

    async loadWeb3() {
        //checking if status code of 1
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            try {

                await window.ethereum.enable();
                this.CheckNetwork();
                let accounts = await window.ethereum.request({method : 'eth_requestAccounts'});
                let userAccount = accounts[0]
                this.setState({ account: userAccount })
            } catch (e) {
                //status code of 2
                this.setState({ loading: false })
                this.setState({ statusCode: 2 })
            }

        } else {
            window.alert('No Ethereum browser detected....check out Metamask!');
            this.setState({ loading: false })
            this.setState({ statusCode: 1 })
        }


    }


    async CheckNetwork() {
        const networkId = await window.web3.eth.net.getId();
        if (networkId !== 361) {
            this.setState({ loading: false })
            this.setState({ statusCode: 3 })
            return;
        }

        this.CheckIfOwnMeepie();
    }

    async CheckIfOwnMeepie() {
        let address = this.state.account
        //check theta scan
        const url = `https://www.thetascan.io/api/721/?address=${address}&type=list&sort=contract`; //fake address
        // //0xEFEeEfBe46E9E91dd5cc5689f1A3867F073914D9 owns a meep
        // const url = `https://www.thetascan.io/api/721/?address=0xEFEeEfBe46E9E91dd5cc5689f1A3867F073914D9&type=list&sort=contract`;//"Stealing Meeps"
        const result = await fetch(url);
        const body = await result.json();

        const meepContracts = [
            '0x38af6ddf4f3f3b044bd0ae1106d6726a011eefd1',   //888 total
            '0x441c01707404d61391fbbf69cb64e89389d842ae',
            '0x05f5c820f274c2510d03d0ddd6a4e2564adedfa5',
            '0x06b656c87f98ec1aadb2c6ad2fb68a748befc71e'];


        Object.entries(body).every(([key, value]) => {
            if (meepContracts.includes(value.contract)) {
                this.setState({ statusCode: 0 })
                this.StoreURIs(body, meepContracts);
                return false;
            } else {
                this.setState({ statusCode: 4 })
            }
            return true;
        })

    }


    async StoreURIs(ownedNFTs, meepContracts) {
        let targetAttemps = 0;
        this.setState({ attempts: 0 });

        Object.entries(ownedNFTs).forEach(([key, value]) => {
            if (meepContracts.includes(value.contract)) {
                targetAttemps++;
                this.setState({ targetAttemps: targetAttemps });
                this.getUri(value.contract, value.token);
            }
        })
    }

    async getUri(nftAddress, tokenID) {
        const web3 = new Web3(new Web3.providers.HttpProvider("https://eth-rpc-api.thetatoken.org/rpc"));
        const defaultNFTinfo = await new web3.eth.Contract(CoolNFT.abi, nftAddress); //Need a test NFT 
        let result = await defaultNFTinfo.methods.tokenURI(tokenID).call()

        let attempts = this.state.attempts;
        attempts++;
        this.setState({ attempts });


        listofNfts.push(result);

        if (this.state.targetAttemps === this.state.attempts) {
            sessionStorage.setItem("walletAddress", this.state.account)
            sessionStorage.setItem("meepNFTs", listofNfts)
            this.setState({ loading: false })
            window.location.replace('./play');
        }
        return result;
    }


    render() {
        return (
            <div className='home'>
                <div className='home-image-container'>
                    <img src='./img/Meemop_Maze_Mayhem.png' alt='Meemop Main Image' />
                </div>
                {
                    this.state.loading &&
                    <div style={{fontSize:'20pt'}}>
                        Loading...
                    </div>
                }

                {
                    (!this.state.loading && this.state.statusCode === 0) &&
                    <div>
                        <a href='./play'>
                            <button>Play Game!</button>
                        </a>
                    </div>
                }

                {
                    (!this.state.loading && this.state.statusCode === 1) &&
                    <div style={{fontSize:'20pt'}}>
                        Error! Metamask not detected.
                    </div>
                }

                {
                    (!this.state.loading && this.state.statusCode === 2) &&
                    <div style={{fontSize:'20pt'}}>
                        Metamask not connected.
                        Please check Metamask and refresh.
                    </div>
                }

                {
                    (!this.state.loading && this.state.statusCode === 3) &&
                    <div style={{fontSize:'20pt'}}>
                        Metamask is not connect to the right network.
                        Please check the network and refresh.
                    </div>
                }


                {
                    (!this.state.loading && this.state.statusCode === 4) &&
                    <div style={{fontSize:'20pt'}}>
                        Sorry this account doesn't own meepie
                    </div>
                }

            </div>

        )
    }
}

export default Home;
