import React, { Component, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Game from './Game';


function App(){

        return (
            <div className='app-main'>
                <Router>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/play' element={<Game />} />
                        <Route exact path='/*' element={<Home />} />

                    </Routes>
                </Router>
            </div>

        )
}

export default App;
