import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";


const checkForStoreage = () =>{
    if(sessionStorage.getItem("meepNFTs") == null ){
       window.location.replace('https://opentheta.io/collection/meemopmania');
    }
 }

function Game() {
    const { unityProvider } = useUnityContext({
        loaderUrl: "./../pacman/mm.loader.js",
        dataUrl: "./../pacman/mm.data",
        frameworkUrl: "./../pacman/mm.framework.js",
        codeUrl: "./../pacman/mm.wasm",
    });
  

    checkForStoreage();
   

    return (

        <div style={{width:'100%', height:'100vh', background:'black'}}>
            <Unity unityProvider={unityProvider} style={{width:'100%', height:'99vh'}} />
        </div>
    );
  }

export default Game;
